import {MutableRefObject, useEffect, useState} from 'react';

export const useHideControls = (ref: MutableRefObject<HTMLElement | null>, initial = false) => {
  const [visible, setVisible] = useState(initial);
  useEffect(() => {
    let mounted = true;
    const node = ref.current;
    if (node === null) {
      return undefined;
    }
    let timeout: any = 0;
    const refreshTimer = () => {
      if (timeout) {
        clearTimeout(timeout);
        timeout = 0;
      }
      timeout = setTimeout(hide, 5000);
    };
    const show = () => {
      if (mounted) {
        setVisible(true);
        refreshTimer();
      }
    };
    const hide = () => {
      clearTimeout(timeout);
      if (mounted) {
        setVisible(false);
      }
    };
    node.addEventListener('mouseenter', show);
    node.addEventListener('mousemove', show);
    node.addEventListener('mouseleave', hide);
    return () => {
      node.removeEventListener('mouseenter', show);
      node.removeEventListener('mousemove', show);
      node.removeEventListener('mouseleave', hide);
      mounted = false;
    };
  }, [ref]);
  return visible;
};
