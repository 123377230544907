import React, {createContext, FC, useCallback, useContext, useMemo, useRef, useState} from 'react';
import {
  getPTZData,
  IPtzData,
  IPtzSettings,
  ptzAbsoluteMove,
  ptzContinuousMove,
  ptzRelativeMove,
  ptzStop
} from '../helpers/requests';
import {useSensitivity} from './useSensitivity';
import {useMoving} from './useMoving';
import {IPtzValues} from '../helpers/calculatePtz';

export interface IMoveCommands {
  loading: boolean;
  loadCurrentData(): Promise<IPtzData | null | false>;
  moveDownLeft(): Promise<void>;
  moveRight(): Promise<void>;
  moveUpLeft(): Promise<void>;
  moveLeft(): Promise<void>;
  moveDownRight(): Promise<void>;
  moveDown(): Promise<void>;
  moveUp(): Promise<void>;
  moveUpRight(): Promise<void>;
  zoomIn(): Promise<void>;
  zoomOut(): Promise<void>;
  stop(): Promise<void>;

  absoluteZoom(zoom: number): void;
  absoluteMove(position: IPtzValues): void;

  pointZoom(transform: (absolute: IPtzData) => IPtzValues): void;
}
const TIME_BETWEEN_REQUEST = 1000;
const CommandsContext = createContext<IMoveCommands>(null!);

export const useCommands = (): IMoveCommands => {
  return useContext(CommandsContext);
};

export const CommandsProvider: FC<{cameraId: string; ptzSettings?: IPtzSettings}> = ({
  cameraId,
  children,
  ptzSettings
}) => {
  const [loading, setLoading] = useState(false);
  const [currentData, setPtzData] = useState<IPtzData | null | false>(null);
  const reqIdRef = useRef(0);
  const currentReq = useRef<Promise<IPtzData | null | false> | null>(null);
  const loadCurrentData = useCallback(() => {
    const reqId = ++reqIdRef.current;
    setLoading(true);
    const req = getPTZData(cameraId).then((data) => {
      currentReq.current = null;
      if (reqIdRef.current === reqId) {
        setPtzData(data);
        setLoading(false);
      }
      return data;
    });
    currentReq.current = req;
    return req;
  }, [cameraId]);
  const value = {
    loading,
    currentData,
    loadCurrentData,
    ...useContMoveCommands(cameraId, ptzSettings),
    ...useAbsoluteMove(cameraId, loadCurrentData),
    ...usePointZoom(cameraId, loadCurrentData)
  };
  return <CommandsContext.Provider value={value}>{children}</CommandsContext.Provider>;
};

const useContMoveCommands = (cameraId: string, ptzSettings?: IPtzSettings) => {
  const {sensitivitySettings} = useSensitivity();
  return useMemo(() => {
    const offset = sensitivitySettings.value;

    const createPointerUpListener = (onUp: (e: WindowEventMap['pointerup']) => void) => {
      const cleanUp = () => window.removeEventListener(...params);
      const params: [string, (e: any) => void, {capture: boolean; once: boolean}] = [
        'pointerup',
        (e) => {
          cleanUp();
          onUp(e);
        },
        {capture: true, once: true}
      ];
      window.addEventListener(...params);
      return cleanUp;
    };

    const moveContinuously = async (speed: {x?: number; y?: number; zoom?: number}) => {
      const request = ptzContinuousMove(cameraId, speed, ptzSettings);
      const requestStartTime = Date.now();
      createPointerUpListener(() => {
        request.then(() => {
          const requestStopTime = Date.now() - requestStartTime;
          if (requestStopTime > TIME_BETWEEN_REQUEST) {
            ptzStop(cameraId);
            return;
          }

          setTimeout(() => ptzStop(cameraId), TIME_BETWEEN_REQUEST);
        });
      });
    };
    return {
      stop: () => ptzStop(cameraId),
      moveLeft() {
        return moveContinuously({
          x: -offset
        });
      },
      moveRight() {
        return moveContinuously({
          x: offset
        });
      },
      moveUp() {
        return moveContinuously({
          y: offset
        });
      },
      moveDown() {
        return moveContinuously({
          y: -offset
        });
      },
      moveUpLeft() {
        return moveContinuously({
          x: -offset,
          y: offset
        });
      },
      moveUpRight() {
        return moveContinuously({
          x: offset,
          y: offset
        });
      },
      moveDownLeft() {
        return moveContinuously({
          x: -offset,
          y: -offset
        });
      },
      moveDownRight() {
        return moveContinuously({
          x: offset,
          y: -offset
        });
      },
      zoomIn() {
        return moveContinuously({
          zoom: offset
        });
      },
      zoomOut() {
        return moveContinuously({
          zoom: -offset
        });
      },
    };
  }, [cameraId, ptzSettings, sensitivitySettings.value]);
};

const useAbsoluteMove = (cameraId: string, loadPtzData: IMoveCommands['loadCurrentData']) => {
  const {setMoving} = useMoving();
  return useMemo(() => {
    return {
      absoluteZoom: (zoom: number) => {
        setMoving(() =>
          loadPtzData().then((data) => {
            if (typeof data === 'object' && data !== null) {
              return ptzAbsoluteMove(cameraId, {
                ...data.status.position,
                zoom
              });
            }
            return undefined;
          })
        );
      },
      absoluteMove: (position: IPtzValues) => {
        return ptzAbsoluteMove(cameraId, position);
      }
    };
  }, [loadPtzData, cameraId, setMoving]);
};

const usePointZoom = (cameraId: string, loadPtzData: IMoveCommands['loadCurrentData']) => {
  const {setMoving} = useMoving();
  return useMemo(() => {
    return {
      pointZoom: (transform: (absolute: IPtzData) => IPtzValues) => {
        setMoving(() =>
          loadPtzData().then((data) => {
            if (data) {
              return ptzRelativeMove(cameraId, transform(data));
            }
            return undefined;
          })
        );
      }
    };
  }, [loadPtzData, cameraId, setMoving]);
};
