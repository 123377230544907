import React, {FC, useEffect, useCallback, useState, Fragment, Suspense} from 'react';

import {observer} from 'mobx-react-lite';

import {IStore} from '../../../IStore';
import {useWidgetProps} from '../../../hooks';
import {isAlive} from 'mobx-state-tree';
import {IPtzSettings, getPTZData} from '../../../components/ptzControls/helpers/requests';
import {isNull} from 'lodash-es';
import {useSensitivity} from '../../../components/ptzControls/hooks/useSensitivity';
import {CommandsProvider} from '../../../components/ptzControls/hooks/useCommands';

const LazyPtzControls = React.lazy(() => import('../../../components/ptzControls/LazyPtzControls'));

type PtzControlsAdapterType = {
  cameraId: string;
  store: IStore;
  onControlsMounted: () => void;
  ptzControllerDisable: boolean;
  setIsPtzConnectionError: (f: boolean) => void;
};

export const PtzControlsAdapter: FC<PtzControlsAdapterType> = observer(
  ({cameraId, store, children, onControlsMounted, ptzControllerDisable, setIsPtzConnectionError}) => {
    const {ptzRequiresFullscreen, initiallyShowPtz} = useWidgetProps();
    const [ptzSettings, setPtzSettings] = useState<IPtzSettings | undefined>();
    const {setSensitivitySettings} = useSensitivity();
    const onClose = useCallback(() => store.setIsPtzVisible(false), [store]);
    const {
      isFullscreen,
      isPtzSupported,
      naturalSize: {width, height}
    } = store;
    const fullscreenImpact = ptzRequiresFullscreen ? isFullscreen : true;

    useEffect(() => {
      if (!fullscreenImpact || width <= 0 || height <= 0 || !isAlive(store)) return;

      getPTZData(cameraId).then((value) => {
        if (!isAlive(store)) return;
        // case when doesn't have ptz fields
        if (typeof value !== 'object') {
          store.setIsPtzSupported(value);
          return;
        }
        // case when isPtz but status undefined
        if (isNull(value)) {
          store.setIsPtzSupported(true);
          store.setIsPtzVisible(false);
          store.setIsDisablePtz(true);
          initiallyShowPtz && setIsPtzConnectionError(true);
          return;
        }
        setSensitivitySettings({value: value?.ptzSettings?.sensitivity || 0.05});
        setPtzSettings(value?.ptzSettings);
        // case when have all data
        store.setIsPtzSupported(true);
        initiallyShowPtz && store.setIsPtzVisible(true);
      });
    }, [initiallyShowPtz, fullscreenImpact, width, height, store, cameraId, setIsPtzConnectionError]);

    if (fullscreenImpact && isPtzSupported && width > 0 && height > 0) {
      return (
        <Fragment>
          <CommandsProvider cameraId={cameraId} ptzSettings={ptzSettings}>
            <Suspense fallback={null}>
              <LazyPtzControls
                mounted={onControlsMounted}
                disable={ptzControllerDisable}
                ptzSettings={ptzSettings}
                cameraId={cameraId}
                naturalHeight={height}
                naturalWidth={width}
                visible={store.isPtzVisible}
                onClose={onClose}
              />
              {!store.isPtzVisible && children}
            </Suspense>
          </CommandsProvider>
        </Fragment>
      );
    }

    return <Fragment>{children}</Fragment>;
  }
);
