import {css} from '@emotion/react';

export const overlayCss = css`
  & {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
`;

/**
 *  use as style, since there is a conflict when ratio === 'fit' with setFit() during initPlyr,
 *  because class is getting overwritten
 */

export const relativeStyle = {
  position: 'relative',
  width: '100%'
} as const;

export const defaultVideoStyles = {
  width: '100%',
  height: '100%'
}

export const mainContainer = css`
  &.main-container {
    height: 100%;
  }
  &.main-container.loading {
    min-height: 100px;
    background: var(--bg);
  }
`;

export const gradientCss = css`
  & {
    position: absolute;
    bottom: 0;
    left: 0;
    padding-top: calc(45rem / var(--bfs));
    height: calc(200rem / var(--bfs));
    width: 100%;
    background-image: var(--player-controls-gradient);
  }
`;

export const visibleCss = css`
  &[data-visible] {
    transition: opacity var(--transition-duration) ease;
  }
  &[data-visible='false'] {
    opacity: 0;
  }
  &[data-visible='true'] {
    opacity: 1;
  }
`;

export const panelPositionCss = css`
  & {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
`;