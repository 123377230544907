/** @jsx jsx */
import {jsx} from '@emotion/react';
import {previewContainer} from './style';

export const NoStreams = () => {
  return (
    <div css={previewContainer}>
      <svg width="360" height="360" viewBox="5 0 360 360" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity=".7" filter="url(#no-stream-a)">
          <path d="m94 208 192-77-114 125-57 18-21-66Z" fillRule="evenodd" fill="url(#no-stream-b)" />
        </g>
        <g opacity=".7" filter="url(#no-stream-c)">
          <path d="M280 210 108 94l86 146 52 30 34-60Z" fillRule="evenodd" fill="url(#no-stream-d)" />
        </g>
        <path d="m118 202 144-58-86 94-42 13-8-24-8-25Z" fillRule="evenodd" fill="url(#no-stream-e)" opacity=".8" />
        <path d="m257 203-129-86 65 109 38 22 26-45Z" fillRule="evenodd" fill="url(#no-stream-f)" opacity=".8" />
        <path d="m134 251 128-107-86 94-42 13Z" fillRule="evenodd" fill="url(#no-stream-g)" opacity=".8" />
        <path d="M231 248 128 117l65 109 38 22Z" fillRule="evenodd" fill="url(#no-stream-h)" opacity=".8" />
        <defs>
          <linearGradient id="no-stream-b" x1="351.6" y1="99.9" x2="80.7" y2="245.6" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FF3C3C" />
            <stop offset={1} stopColor="#FF3C3C" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="no-stream-d" x1="50.1" y1={50} x2="285.1" y2="248.9" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FF3C3C" />
            <stop offset={1} stopColor="#FF3C3C" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="no-stream-e" x1="310.9" y1={121} x2="107.9" y2="230.2" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FF3C3C" />
            <stop offset=".8" stopColor="#FF3C3C" stopOpacity=".2" />
            <stop offset={1} stopColor="#FF3C3C" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="no-stream-f" x1="84.9" y1="83.6" x2={261} y2="232.6" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FF3C3C" />
            <stop offset={1} stopColor="#FF3C3C" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="no-stream-g" x1={97} y1="220.6" x2="152.5" y2="265.9" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FF3C3C" />
            <stop offset={1} stopColor="#FF3C3C" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="no-stream-h" x1="273.6" y1="225.5" x2={210} y2="258.3" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FF3C3C" />
            <stop offset={1} stopColor="#FF3C3C" stopOpacity={0} />
          </linearGradient>
          <filter
            id="no-stream-a"
            x={0}
            y="36.9"
            width="380.1"
            height="331.1"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB">
            <feFlood floodOpacity={0} result="BackgroundImageFix" />
            <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="31.3" result="effect_layerBlur_1" />
          </filter>
          <filter
            id="no-stream-c"
            x="13.8"
            y={0}
            width={360}
            height="363.5"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB">
            <feFlood floodOpacity={0} result="BackgroundImageFix" />
            <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="31.3" result="effect_layerBlur_1" />
          </filter>
        </defs>
      </svg>
      <div className="error-description">Нет потока</div>
    </div>
  );
};
