import {IStore} from '../../IStore';
import {IObservableBox, observableBox} from '../../utils/observable';
import {FC, useEffect} from 'react';
import {reaction} from 'mobx';
import {createDisposers} from '../../utils/disposers';
import {useWidgetProps} from '../../hooks';

const busMap: Map<string, IObservableBox<boolean>> = new Map();

export const PlayingGroup: FC<{store: IStore}> = ({store}) => {
  const {playingGroupId} = useWidgetProps();
  useEffect(() => {
    if (playingGroupId === undefined) {
      return;
    }
    const bus =
      busMap.get(playingGroupId) ??
      // inlined to make bus a const for better types inference
      (() => {
        const box = observableBox<boolean>(store.isPlaying);
        busMap.set(playingGroupId, box);
        return box;
      })();
    const disposers = createDisposers();

    // true in order to skip the very first value from subscription
    let skip = true;

    disposers.add(
      reaction(
        () => store.isPlaying,
        (playing) => {
          if (playing) {
            skip = true;
            bus.set(true);
          }
        }
      )
    );
    disposers.add(
      // fires immediately
      bus.subscribe((value) => {
        if (value) {
          if (skip) {
            skip = false;
          } else {
            setTimeout(() => {
              store.setIsPlaying(false, 'user');
            }, 100);
          }
        }
      })
    );
    return disposers.flush;
  }, [playingGroupId, store]);
  return null;
};
