import {useLayoutEffect, useMemo, useRef} from 'react';

export function useMountedRef() {
  const mountedRef = useRef(false);
  useLayoutEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);
  return mountedRef;
}

export function useSafeAsync() {
  const mountedRef = useRef(false);
  useLayoutEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);
  return useMemo(
    () => <F extends (...args: any[]) => void>(func: F): F =>
      new Proxy(func, {
        apply(target: any, thisArg: any, argArray?: any): any {
          if (mountedRef.current) {
            return Reflect.apply(target, thisArg, argArray);
          }
        }
      }),
    [mountedRef]
  );
}
