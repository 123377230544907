/**@jsx jsx */
import {css, jsx} from '@emotion/react'
import React, {FC, useState} from 'react'

import {Button} from 'primereact/button';

import { observer } from 'mobx-react-lite';

import {ICameraPreset} from '../../../components/ptzControls/helpers/requests';
import {WithPreview} from '../../../components/ptzControls/Presets';
import {usePresetsCommands} from '../../../components/ptzControls/hooks/usePresets';

import {useLocale, useGetActiveStream} from '../../../hooks'

const $ptzButton = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(32rem / var(--bfs));
`;


export const CreatePTZPreview: FC = observer(() => {
    const locale = useLocale();
    const [loading, setLoading] = useState(false);
    const {createPreset} = usePresetsCommands();
    const onClick = () => {
      setLoading(true);
      createPreset(locale.ptzControls.defaultPresetTitle).finally(() => {
        setLoading(false);
      });
    };
    return (
      <div css={$ptzButton}>
        <Button
          disabled={loading}
          onClick={onClick}
          className={'p-button-text'}
          label={locale.controls.createPresetImage}
          icon={loading ? 'mdi mdi-18px mdi-loading mdi-spin' : 'mdi mdi-20px camera'}
        />
      </div>
    );
  });
  
  export const UpdatePTZPreview: FC<{presets: ICameraPreset[]}> = observer(({presets}) => {
    const locale = useLocale();
    const [loading, setLoading] = useState(false);
    const {updatePreset} = usePresetsCommands();
    const {activeStream} = useGetActiveStream();
    const getEditedTitle = (title: string) => {
      return title.endsWith('.') ? title.substring(0, title.length - 1) : `${title}.`
    }
    const onClick = () => {
      if (!presets[0]?.id) return
      setLoading(true);
      updatePreset({
        presetId: presets[0].id,
        title: getEditedTitle(presets[0].title),
        streamId: activeStream?.id
      })
        .finally(() => setLoading(false))
    };
    return (
      <div
        css={css`
          position: relative;
        `}>
        <WithPreview presetId={presets[0]?.id}>
          <Button
            disabled={loading}
            onClick={onClick}
            className={'p-button-text'}
            label={locale.ptzControls.presets.refreshPresetImage}
            icon={loading ? 'mdi mdi-18px mdi-loading mdi-spin' : 'mdi mdi-20px mdi-camera'}
          />
        </WithPreview>
      </div>
    );
  });