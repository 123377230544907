/** @jsx jsx */
import {jsx} from '@emotion/react';
import {makeButton} from './makeButton';

export const Play = makeButton((props) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9998 2.6665C8.63984 2.6665 2.6665 8.63984 2.6665 15.9998C2.6665 23.3598 8.63984 29.3332 15.9998 29.3332C23.3598 29.3332 29.3332 23.3598 29.3332 15.9998C29.3332 8.63984 23.3598 2.6665 15.9998 2.6665ZM13.3332 21.9998L21.3332 15.9998L13.3332 9.99984V21.9998ZM5.33317 15.9998C5.33317 21.8798 10.1198 26.6665 15.9998 26.6665C21.8798 26.6665 26.6665 21.8798 26.6665 15.9998C26.6665 10.1198 21.8798 5.33317 15.9998 5.33317C10.1198 5.33317 5.33317 10.1198 5.33317 15.9998Z"
        fill="#959DA8"
      />
    </svg>
  );
});
