import {createContext, createElement, FC, useCallback, useContext, useEffect, useState} from 'react';
import {useMountedRef} from '../../../hooks';

export interface IMovingContext {
  isMoving: boolean;
  setMoving: (callback: () => Promise<void>) => void;
}

const MovingContext = createContext<IMovingContext>(null!);

export const MovingProvider: FC = ({children}) => {
  const mountedRef = useMountedRef();
  const [movingPromise, setMovingPromise] = useState<Promise<void> | null>(null);
  const setMoving = useCallback<IMovingContext['setMoving']>(
    (callback) => {
      setMovingPromise((current) =>
        current === null
          ? callback()
          : current.then(() => {
              mountedRef.current && callback();
            })
      );
    },
    [mountedRef]
  );
  useEffect(() => {
    if (movingPromise !== null) {
      movingPromise.finally(() => {
        mountedRef.current && setMovingPromise(null);
      });
    }
  }, [movingPromise, mountedRef]);
  return createElement(MovingContext.Provider, {value: {isMoving: !!movingPromise, setMoving}}, children);
};

export const useMoving = () => useContext(MovingContext);
