import React, {createContext, useMemo, useState} from 'react';

interface LockingInfo {
  isLoading: boolean;
  lockingData: null | {
    id: string;
    status: string;
    period: number;
    start: number;
  };
  canI: {
    someLocked: boolean;
    watchLockedStreams: boolean;
    changeLockedControls: boolean;
  };
  is: {
    streamLockedNow: boolean;
    controlsLockedNow: boolean;
  };
}

export const INITIAL_DATA: LockingInfo = {
  isLoading: true,
  lockingData: null,
  canI: {
    someLocked: false,
    watchLockedStreams: false,
    changeLockedControls: false
  },
  is: {
    streamLockedNow: false,
    controlsLockedNow: false
  }
};

export const Context = createContext<{
  setter: React.Dispatch<React.SetStateAction<LockingInfo>>;
  getter: LockingInfo;
}>(null!);

export const Provider = ({children}: {children: React.ReactNode}) => {
  const [lockingInfo, setLockingInfo] = useState<LockingInfo>(INITIAL_DATA);

  const accessors = useMemo(
    () => ({
      setter: setLockingInfo,
      getter: lockingInfo
    }),
    [lockingInfo]
  );

  return <Context.Provider value={accessors}>{children}</Context.Provider>;
};
