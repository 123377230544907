import React from 'react';

import {Dialog} from 'primereact/dialog';
import {Button} from 'primereact/button';

import {useLocale} from '../../hooks';

type PTZConnectProps = {
  onHide: () => void;
};

export const PTZConnect: React.FC<PTZConnectProps> = ({onHide}) => {
  const locale = useLocale().ptzControls.connectionError;
  return (
    <Dialog
      closable={false}
      onHide={onHide}
      visible
      header={locale.header}
      footer={<Button onClick={onHide} className={'p-button-secondary p-button-outlined'} label={locale.close} />}>
      <div className={'p-px-3'}>{locale.message}</div>
    </Dialog>
  );
};
