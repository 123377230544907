import {
  createCamerasConnection,
  gatewayPath,
  getFiwareHeaders,
  ICamera,
  IEntity,
  listEntities
} from '@netvision/lib-api-gateway';
import {IPtzConstants, IPtzValues} from './calculatePtz';
import {removeUndefined} from '../../../utils/removeUndefined';

const saveCameraTimeoutMoveValue = (timeout?: number) => {
  const DEFAULT_CAMERA_TIMEOUT_MOVE = 30 * 1000; // 30sec
  const MIN_CAMERA_TIMEOUT_MOVE = 1 * 1000; // 1 sec
  const MAX_CAMERA_TIMEOUT_MOVE = 60 * 1000; // 1 min

  const onlyPositive = Math.abs(timeout || DEFAULT_CAMERA_TIMEOUT_MOVE);
  const betweenSecAndMin =
    onlyPositive >= MIN_CAMERA_TIMEOUT_MOVE && onlyPositive <= MAX_CAMERA_TIMEOUT_MOVE
      ? onlyPositive
      : DEFAULT_CAMERA_TIMEOUT_MOVE;

  return betweenSecAndMin;
};

export interface IPtzSettings extends IPtzConstants {
  timeout?: number;
  delay?: number;
  threshold?: IPtzValues;
  sensitivity?: number;
}

interface ICameraWithPTZ extends ICamera {
  status: {
    position: IPtzValues;
  };
  ptzSettings: IPtzSettings;
}

export interface ICameraPreset extends IEntity {
  id: string;
  type: 'CameraPreset';
  cameraId: string;
  title: string;
  status: {
    position: IPtzValues;
  };
  groundCalibration: Record<string, unknown>;
}

export type IPtzData = Pick<ICameraWithPTZ, 'status' | 'ptzSettings'>;
//
const throttleAsync = <T extends (...args: any[]) => Promise<any>>(delay: number, func: T): T => {
  delay = isNaN(delay) ? 0 : Math.max(0, delay);
  let lastCall = {time: 0, res: Promise.resolve<IPtzData | null>(null)};
  return new Proxy(func, {
    apply(target, thisArg, argArray) {
      if (lastCall.time + delay > performance.now()) {
        return lastCall.res;
      }
      const res = Reflect.apply(target, thisArg, argArray);
      lastCall = {time: performance.now(), res};
      return res;
    }
  });
};

export function getPTZData(cameraId: string): Promise<IPtzData | null | false> {
  return listEntities<ICamera>(createCamerasConnection(), {
    id: cameraId,
    type: 'Camera',
    attrs: 'status,ptzSettings'
  }).then((res) => {
    if (res.results.length === 1) {
      const camera = res.results[0] as ICameraWithPTZ;
      if (camera['status'] && camera['ptzSettings']) {
        const {ptzSettings, status} = camera;
        return {
          status,
          ptzSettings
        };
      } else if (!camera?.status && camera?.ptzSettings) {
        console.error(Error('Camera does not have required ptz fields: "status"'), res);
        return null;
      } else {
        console.error(Error('Camera does not have required ptz fields: "status", "ptzSettings"'), res);
        return false;
      }
    } else {
      console.error(Error('Unexpected result, expect exactly one entity'), res);
      return false;
    }
  });
}

export function ptzRelativeMove(cameraId: string, offset: Partial<IPtzValues>): Promise<void> {
  return createCamerasConnection().v2.updateEntityAttributes(
    {
      id: cameraId,
      type: 'Camera',
      relativeMove: {
        x: 0,
        y: 0,
        zoom: 0,
        speed: 1,
        ...offset
      }
    },
    {keyValues: true}
  );
}

export function ptzContinuousMove(
  cameraId: string,
  speed: Partial<IPtzValues>,
  ptzSettings?: IPtzSettings
): Promise<void> {
  return createCamerasConnection().v2.updateEntityAttributes(
    {
      id: cameraId,
      type: 'Camera',
      continuousMove: {
        timeout: saveCameraTimeoutMoveValue(ptzSettings?.timeout),
        x: 0,
        y: 0,
        zoom: 0,
        ...speed
      }
    },
    {keyValues: true}
  );
}

export function ptzAbsoluteMove(cameraId: string, position: Partial<IPtzValues>): Promise<void> {
  return createCamerasConnection().v2.updateEntityAttributes(
    {
      id: cameraId,
      type: 'Camera',
      absoluteMove: {
        ...position
      }
    },
    {keyValues: true}
  );
}

export function ptzStop(cameraId: string): Promise<void> {
  return createCamerasConnection().v2.updateEntityAttributes(
    {
      id: cameraId,
      type: 'Camera',
      stop: {}
    },
    {keyValues: true}
  );
}

export function ptzGetPresets(cameraId: string): Promise<ICameraPreset[]> {
  return listEntities<ICameraPreset>(createCamerasConnection(), {
    type: 'CameraPreset',
    q: `cameraId==${cameraId}`,
    limit: 1000
  })
    .then((res) => {
      return res.results;
    })
    .catch((e) => {
      console.error(e);
      return [];
    });
}

export function ptzGoToPreset(cameraId: string, presetId: string): Promise<void> {
  return createCamerasConnection().v2.updateEntityAttributes(
    {
      id: cameraId,
      type: 'Camera',
      gotoPreset: {
        preset: presetId
      }
    },
    {keyValues: true}
  );
}

export function ptzCreatePreset(cameraId: string, title?: string) {
  const uuid_v4 = () => {
    return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, (c: string | number) => {
      c = Number(c);
      return (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16);
    });
  };
  return getPTZData(cameraId).then((data) => {
    const payload = removeUndefined({
      id: uuid_v4(),
      type: 'CameraPreset',
      cameraId,
      title,
      status: data ? data?.status : undefined,
      groundCalibration: {}
    });
    return createCamerasConnection().v2.createEntity(payload, {keyValues: true});
  });
}

export function ptzSetPreset(presetId?: string, title?: string): Promise<void> {
  const payload = removeUndefined({
    id: presetId,
    type: 'CameraPreset',
    title
  });
  return createCamerasConnection().v2.updateEntityAttributes(payload, {keyValues: true});
}

export function ptzRemovePreset(cameraId: string, presetId: string): Promise<void> {
  return createCamerasConnection().v2.deleteEntity({
    id: presetId,
    type: 'CameraPreset',
    q: `cameraId==${cameraId}`
  });
}

export function updatePresetImage(
  presetId: string,
  presetTitle: string,
  currentPtzStatus = {} as IPtzValues,
  streamId?: string
): Promise<void> {
  return createCamerasConnection().v2.appendEntityAttributes(
    {
      id: presetId,
      title: presetTitle,
      type: 'CameraPreset',
      updatePreviewCommand: {...currentPtzStatus, ...(streamId ? {streamId} : {})}
    },
    {keyValues: true}
  );
}

export function fetchPresetPreview(presetId: string, signal?: AbortSignal) {
  return fetch(`${gatewayPath}/cameras/v1/presets/${presetId}`, {
    headers: getFiwareHeaders(),
    signal
  }).then((res) => {
    if (res.status === 200) {
      return res.blob();
    } else {
      throw Error('Preview cannot be fetched');
    }
  });
}

export function updatePtzSettings(cameraId: string, ptzSettings: IPtzSettings) {
  createCamerasConnection().v2.updateEntityAttributes(
    {
      id: cameraId,
      type: 'Camera',
      ptzSettings
    },
    {keyValues: true}
  );
}
