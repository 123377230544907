import React from 'react';

import {Dialog} from 'primereact/dialog';
import {Button} from 'primereact/button';

import {useLocale} from '../../hooks';

type PTZBlockedProps = {
  onHide: () => void;
};

export const PTZBlocked: React.FC<PTZBlockedProps> = ({onHide}) => {
  const {blocked, close} = useLocale().ptzControls;
  return (
    <Dialog
      closable
      onHide={onHide}
      visible
      header={blocked.header}
      footer={<Button onClick={onHide} className={'p-button-secondary p-button-outlined'} label={close} />}>
      <div className={'p-px-3'}>{blocked.message}</div>
    </Dialog>
  );
};
