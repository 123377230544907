/**@jsx jsx */
import {css, jsx} from '@emotion/react'

import React, { FC, Fragment } from "react";

import {Dialog} from 'primereact/dialog'
import {Button} from 'primereact/button'

import { useDialogVisible, useLocale } from '../../hooks'

const $unlockMessage = css`
  width: calc(480rem / var(--bfs));
  line-height: 1.5;
`;

export const PTZUnLock: FC<{count: number; onHide: (result: boolean) => void}> = ({count, onHide: _onHide}) => {
    const locale = useLocale().ptzControls.unlock;
    const [visible, onHide] = useDialogVisible(_onHide);

    const onCancel = () => onHide(false);
    const onProceed = () => onHide(true);
    return (
      <Dialog
        visible={visible}
        closable={false}
        onHide={onCancel}
        header={locale.header}
        footer={
          <Fragment>
            <Button
              icon={'mdi mdi-18px mdi-lock-open-variant-outline'}
              className={'p-button-danger'}
              label={locale.yes}
              onClick={onProceed}
            />
            <Button onClick={onCancel} className={'p-button-secondary p-button-outlined'} label={locale.no} />
          </Fragment>
        }>
        <div className={'p-px-3'} css={$unlockMessage}>
          {locale.message.replace('{{}}', count.toString())}
        </div>
      </Dialog>
    );
  };
  