import { useLayoutEffect, useState } from "react";

import {IStreamAdapter} from '../streamApdapters/IStreamAdapter';
import {EStreamType} from '../streamApdapters/EStreamType';
import {selectStreamAdapter} from '../streamApdapters/selectStreamAdapter';

export const useStreamAdapter = (streamType?: string): IStreamAdapter | null => {
  const [adapter, setAdapter] = useState<IStreamAdapter | null>(null);

  useLayoutEffect(() => {
    let aborted = false;
    let type;

    switch (streamType?.toUpperCase()) {
      case 'HLS':
        type = EStreamType.HLS;
        break;
      case 'SLDP':
      default:
        type = EStreamType.SLDP;
    }

    selectStreamAdapter(type).then((adapter) => {
      if (aborted) return;
      setAdapter(adapter);
    });

    return () => {
      aborted = true;
    };
  }, [streamType]);

  return adapter;
};
