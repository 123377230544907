/** @jsx jsx */
import {jsx} from '@emotion/react';
import {makeButton} from './makeButton';

export const ExitFullscreen = makeButton((props) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M11.8334 26.8336V20.1669H5.16675V17.5002H14.5001L14.4899 26.8336H11.8334Z" fill="#959DA8" />
      <path d="M20.1667 5.1665L20.1667 11.8332H26.8333V14.4998L17.5 14.4998L17.5102 5.1665H20.1667Z" fill="#959DA8" />
    </svg>
  );
});
