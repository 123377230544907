/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {FC, useState} from 'react';
import {Slider} from 'primereact/slider';
import {Volume, WithPopup, Muted} from './buttons';
import {IPlayerButtonProps} from './buttons/makeButton';
import {e2eModule} from '../../../__test__';

export type IVolumeControlProps = {
  /** number from 0 to 1 */
  value: number;
  onChange: (newValue: number) => void;
  muted: boolean;
  toggleMute: () => void;
} & Pick<IPlayerButtonProps, 'tooltip' | 'tooltipAlign'>;

export const VolumeControl: FC<IVolumeControlProps> = ({value, onChange, muted, toggleMute, tooltip, tooltipAlign}) => {
  const openState = useState(false);
  return (
    <WithPopup
      state={openState}
      className="volume-wrapper"
      button={
        muted || value === 0 ? (
          <Muted
            data-cy={e2eModule.E2E_ATTRIBUTES.mutedUnMutedBtn}
            data-status="mute"
            onDoubleClick={toggleMute}
            tooltip={tooltip}
            tooltipAlign={tooltipAlign}
            css={$button}
          />
        ) : (
          <Volume
            data-cy={e2eModule.E2E_ATTRIBUTES.mutedUnMutedBtn}
            data-status="unmute"
            onDoubleClick={toggleMute}
            tooltip={tooltip}
            tooltipAlign={tooltipAlign}
            css={$button}
          />
        )
      }
      popup={
        <div data-cy={e2eModule.E2E_ATTRIBUTES.volumeSlider} css={$popup}>
          <Slider
            value={muted ? 0 : value * 100}
            min={0}
            max={100}
            onChange={(val) => {
              if (typeof val.value === 'number') onChange(val.value / 100);
            }}
            orientation={'vertical'}
          />
        </div>
      }
    />
  );
};

const $button = css`
  &[aria-expanded='true'] > div[data-role='tooltip'] {
    opacity: 0;
  }
`;

const $popup = css`
  & {
    position: absolute;
    padding: calc(12rem / var(--bfs)) calc(8rem / var(--bfs));
    top: 0;
    left: 50%;
    border-radius: calc(4rem / var(--bfs));
    background: var(--surface-b);
    transform: translateY(-100%) translateX(-50%);
    box-shadow: var(--shadow-raised);
  }
`;
