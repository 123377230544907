/** @jsx jsx */
import {jsx} from '@emotion/react';
import {previewContainer} from './style';
import { e2eModule } from '../../__test__';

export const StreamError = () => {
  return (
    <div css={previewContainer} data-cy={e2eModule.E2E_ATTRIBUTES.error}>
      <svg width="100%" height="100%" viewBox="300 80 700 280" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M595 3c6 5 17 16 26 35a386 386 0 0 0-53 16l-23-42-4 3 47 86-17 45-81-82 52-37-2-3-53 37-28-28-3 2 79 79-14 18 2 2 14-18 9 10-23 31 3 2 22-31 22 22-13 34a257 257 0 0 1-96-76l13-33-3-1-12 32-9-12-2 1 10 15c13 16 28 31 43 43l-38 11 1 2 40-11c20 16 40 26 52 32l-4 10c-31-2-62-7-92-15-53-13-90-14-121-10-23 4-42 9-61 15l-20 6c-25 7-54 13-93 11-39-3-90-13-162-39l-3 10c72 26 124 36 164 39 41 2 71-4 97-11l20-6c19-6 38-12 60-15 29-4 65-3 116 10 32 8 63 13 95 16h2l3 15-61 13 1 3 61-13 7 39c-33 19-68 33-90 40l1 3c22-7 58-22 92-41 28-17 54-38 69-61h1l29 61c-12 1-26 7-38 15-13 9-24 20-31 27l3 2a196 196 0 0 1 43-33l19 41 5-2-20-41c8-3 15-6 22-6l12 1 5 6 5 11 7 21 4-1a252 252 0 0 0-15-36l30 6c11 4 22 10 30 18l3-2c-8-9-20-15-32-19a161 161 0 0 0-48-9l-28-60a323 323 0 0 0 40 12 2920 2920 0 0 0 273 57l1-3a2919 2919 0 0 1-309-67l32-5c61-13 127-37 202-79l23-4-1-4-11 2 65-40-5-8c-32 20-62 38-91 53a3381 3381 0 0 1-202 27l5-17c5-13 8-29 8-45 0-17-1-34-5-48-3-14-8-26-14-33l-3 3c5 6 10 17 13 31 4 14 5 30 5 45l-32 6c-1-19-6-35-11-48a195 195 0 0 1 18-3v-4a83 83 0 0 0-19 3c-9-20-21-32-28-37l-2 3Zm39 204c-27 2-52 2-77 1l3 15 8 40c27-16 51-35 66-56Zm-1-117c-1-20-6-35-11-48a377 377 0 0 0-52 15l22 40 41-7Zm8 58v-37h-4v38a923 923 0 0 1-56 0l-6-1 17-47 77-13c-1 14-3 28-8 40l-5 19-15 1Zm13 21 1-18a1132 1132 0 0 1-75 2l-7-1-16 46c32 2 65 1 100-4-2-6-3-13-3-21h-57v-4h57Zm197-43a3482 3482 0 0 1-146 20v27h-3v-27l-43 5c-3 18-1 33 2 42l17-3c53-11 109-31 173-64ZM443 242l16 18a435 435 0 0 0-70 36c-9 6-16 13-20 20l3 2c3-7 10-13 19-20 8-6 19-12 30-18l39-18c6 9 10 18 11 27v1h19v-3h-16c-1-9-6-18-11-26l19-7-1-2-20 7c-5-9-12-15-16-19l-2 2Z"
          fill="url(#a)"
          opacity=".3"
        />
        <g filter="url(#b)">
          <ellipse cx="641.5" cy="202.5" rx="63.5" ry="64.5" fill="#FF3C3C" fillOpacity=".3" />
        </g>
        <circle cx="641.3" cy="202.7" r={24} fill="#FF3C3C" fillOpacity=".2" />
        <circle cx="641.3" cy="202.7" r={12} fill="#FF3C3C" />
        <g filter="url(#c)">
          <circle cx="641.5" cy="202.5" r="11.5" fill="#FF3C3C" />
        </g>
        <defs>
          <filter
            id="b"
            x={478}
            y={38}
            width={327}
            height={329}
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB">
            <feFlood floodOpacity={0} result="BackgroundImageFix" />
            <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation={50} result="effect1_foregroundBlur_588_37084" />
          </filter>
          <filter
            id="c"
            x={580}
            y={141}
            width={123}
            height={123}
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB">
            <feFlood floodOpacity={0} result="BackgroundImageFix" />
            <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation={25} result="effect1_foregroundBlur_588_37084" />
          </filter>
          <radialGradient
            id="a"
            cx={0}
            cy={0}
            r={1}
            gradientUnits="userSpaceOnUse"
            gradientTransform="matrix(271.25 0 0 109.16 658 194)">
            <stop stopColor="#fff" />
            <stop offset={1} stopColor="#fff" stopOpacity={0} />
          </radialGradient>
        </defs>
      </svg>
      <div className="error-description">Поток недоступен</div>
    </div>
  );
};
