import {reaction} from 'mobx';
import {useEffect, useRef} from 'react';
import {IStore} from '../IStore';
import {useGetActiveStream} from './useSubStream';
import {useWidgetProps} from './useWidgetProps';

const ONE_SECONDS_IN_MS = 1000;
const FOUR_HOURS = ONE_SECONDS_IN_MS * 60 * 60 * 4;

export const useRefetchLiveStream = (store: IStore | null) => {
  const {refetchLiveStreamInterval = FOUR_HOURS} = useWidgetProps();
  const intervalId = useRef(0);
  const {activeStream, fetchStreams} = useGetActiveStream();

  useEffect(() => {
    if (!activeStream || !store) return;
    const isValidFeatureConfig =
      typeof refetchLiveStreamInterval === 'number' &&
      !isNaN(refetchLiveStreamInterval) &&
      refetchLiveStreamInterval >= ONE_SECONDS_IN_MS;

    if (!isValidFeatureConfig) return;

    const disposers = [
      reaction(
        () => store.isPlaying,
        (flag) => {
          clearInterval(intervalId.current);
          if (!flag) return;
          intervalId.current = window.setInterval(() => fetchStreams(), refetchLiveStreamInterval);
        },
        {
          fireImmediately: true
        }
      ),
      () => clearInterval(intervalId.current)
    ];

    return () => disposers.forEach((f) => f());
  }, [activeStream, fetchStreams, refetchLiveStreamInterval, store]);
};
