export interface IObserver<T> {
  (value: T): void;
}

export interface IDisposer {
  (): void;
}

export interface IObservable<T = undefined> {
  subscribe: (observer: IObserver<T>) => IDisposer;
}

const stub = () => {};

export const observable = <T = undefined>(subscriber: (observer: IObserver<T>) => IDisposer | void): IObservable<T> => {
  return {
    subscribe: (observer) => subscriber(observer) || stub
  };
};

export interface IObservableBox<T = undefined> extends IObservable<T> {
  get: () => T;
  set: (next: T) => void;
}

export const observableBox = <T = undefined>(init: T): IObservableBox<T> => {
  let value = init;
  const clients = new Set<IObserver<T>>();
  return {
    subscribe: (observer) => {
      clients.add(observer);
      observer(value);
      return () => clients.delete(observer);
    },
    set: (next) => {
      value = next;
      clients.forEach((observer) => observer(next));
    },
    get: () => value
  };
};
