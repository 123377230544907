/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {ProgressSpinner} from 'primereact/progressspinner';
import {FC} from 'react';
import {IStore} from '../IStore';
import {observer} from 'mobx-react-lite';

const containerCss = css`
  & {
    position: absolute;
    top: calc(15rem / var(--bfs));
    left: calc(15rem / var(--bfs));
  }
`;

const spinnerCss = css`
  body &.p-progress-spinner {
    pointer-events: none;
    display: block;
    font-size: calc(32rem / var(--bfs));
    width: 1em !important;
    height: 1em !important;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.1);
  }
  body & > svg {
    font-size: inherit;
    width: 1em !important;
    height: 1em !important;
    animation: p-progress-spinner-rotate 2s linear infinite;
  }
  body & > svg > circle {
    stroke-width: calc(5rem / var(--bfs));
  }
`;

export const Spinner: FC<{store: IStore}> = observer(({store}) => {
  return store.isWaiting ? <SpinnerWrapper /> : null;
});

export const SpinnerWrapper = () => (
  <div css={containerCss}>
    <ProgressSpinner css={spinnerCss} />
  </div>
);
