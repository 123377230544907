/**@jsx jsx */
import {jsx, css} from '@emotion/react';
import React, {FC, useCallback, useState} from 'react';

import {Button} from 'primereact/button';

import {observer} from 'mobx-react-lite';
import {isAlive} from 'mobx-state-tree';

import {IStore} from '../../../IStore';

import {useLocale, useMountedRef, useWidgetProps} from '../../../hooks';
import {countActiveAnalyticsByCamera} from '../../../analyticsChecks';
import {usePresets} from '../../../components/ptzControls/hooks/usePresets';

type ActivatePtzButtonProps = {
  store: IStore;
  cameraId: string;
  onConfirmRequired: (count: number) => void;
  setPtzControlsDisabled: (f: boolean) => void;
  setShowPtzConnectionError: (f: boolean) => void;
  setShowPTZBlocked: (f: boolean) => void;
};

const $ptzButton = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(32rem / var(--bfs));
`;

export const ActivatePtzButton: FC<ActivatePtzButtonProps> = observer(
  ({store, cameraId, onConfirmRequired, setPtzControlsDisabled, setShowPtzConnectionError, setShowPTZBlocked}) => {
    const {ptzRequiresFullscreen} = useWidgetProps();
    const locale = useLocale();
    const [loading, setLoading] = useState(false);
    const [, , refreshPresets] = usePresets();
    const mountedRef = useMountedRef();
    const {width, height} = store.naturalSize;

    const onClick = useCallback(() => {
      if (store.isPtzVisible) return store.setIsPtzVisible(false);
      if (store.isDisablePtz) return setShowPtzConnectionError(true);
      if (store.isBlockedControls) return setShowPTZBlocked(true);

      setLoading(true);
      countActiveAnalyticsByCamera(cameraId)
        .then((n) => {
          if (!mountedRef.current) return;
          if (n > 0) return onConfirmRequired(n);
          return refreshPresets();
        })
        .then(() => {
          store?.setIsPtzVisible(true);
          setPtzControlsDisabled(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }, [
      store,
      cameraId,
      mountedRef,
      setShowPtzConnectionError,
      setShowPTZBlocked,
      onConfirmRequired,
      setPtzControlsDisabled,
      refreshPresets
    ]);

    return (ptzRequiresFullscreen ? store.isFullscreen : true) && store.isPtzSupported && width > 0 && height > 0 ? (
      <div css={$ptzButton}>
        <Button
          disabled={loading}
          onClick={onClick}
          className={store.isPtzVisible ? 'p-button-primary' : 'p-button-secondary'}
          label={locale.controls.ptz}
          icon={loading ? 'mdi mdi-18px mdi-loading mdi-spin' : 'mdi mdi-18px mdi-gamepad-variant'}
        />
      </div>
    ) : null;
  }
);
