/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {ChangeEvent, FC, Fragment, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react';
import {genId} from '../../utils/genId';
import {Button} from 'primereact/button';
import {useCommands} from './hooks/useCommands';
import {Dialog} from 'primereact/dialog';
import {fetchPresetPreview, ICameraPreset} from './helpers/requests';
import {IPtzValues} from './helpers/calculatePtz';
import {Dropdown, DropdownProps} from 'primereact/dropdown';
import {InputText} from 'primereact/inputtext';
import {useDialogVisible} from '../../hooks/useDialogVisible';
import {usePresets, usePresetsCommands} from './hooks/usePresets';
import {useLocale} from '../../hooks';
import {countAnalyticsByPreset} from '../../analyticsChecks';
import {checkScope} from '../../permissions';

type IAction = 'Create' | 'Update' | 'Delete' | 'UpdateImage';

const C_defaultCameraThreshold = {
  x: 0.001,
  y: 0.001,
  zoom: 0.003
};

export const Presets: FC = () => {
  const locale = useLocale().ptzControls.presets;
  const {absoluteMove, loadCurrentData, loading: ptzLoading} = useCommands();
  const {updatePreset} = usePresetsCommands();
  const [loading, presets, loadPresets] = usePresets();
  
  const [elId] = useState(genId);
  const [loadingPresetImage, setIsloading] = useState(false);
  const [currentStatusLoading, setCurrentStatusLoading] = useState(false);
  const [selectedPreset, setSelectedPreset] = useState<ICameraPreset | null>(null);
  // find that matches current camera position
  const [matchingPreset, setMatchingPreset] = useState<ICameraPreset | null>(null);
  const [action, setAction] = useState<IAction | null>(null);
  const [accessLoading, setAccessLoading] = useState<IAction | null>(null);
  const [accessDenied, setAccessDenied] = useState<IAction | null>(null);

  const presetsRef = useRef(presets);
  presetsRef.current = presets;

  useEffect(() => {
    if (presets.length) return;
    loadPresets();
  }, [loadPresets, presets]);

  useEffect(() => {
    let aborted = false;
    setMatchingPreset(null);
    setCurrentStatusLoading(true);
    loadCurrentData()
      .then((data) => {
        if (!aborted && data) {
          const eqPosition = getEqPosition(data?.ptzSettings.threshold ?? C_defaultCameraThreshold);
          const matchingPreset = presets.find((p) => eqPosition.equals(data.status?.position, p.status?.position));
          setMatchingPreset(matchingPreset ?? null);
        }
      })
      .finally(() => {
        setCurrentStatusLoading(false);
      });
    return () => {
      aborted = true;
    };
    // only on presets change
    // eslint-disable-next-line
  }, [presets]);
  useEffect(() => {
    if (matchingPreset && selectedPreset === null) {
      setSelectedPreset(matchingPreset);
    }
    // only on matchingPreset change
    // eslint-disable-next-line
  }, [matchingPreset]);

  const updateImageOrDialog = () => {
    setIsloading(true);
    loadCurrentData().then((data) => {
      if (data) {
        const eqPosition = getEqPosition(data?.ptzSettings.threshold ?? C_defaultCameraThreshold);
        if (selectedPreset?.status?.position) {
          const isOnPoint = eqPosition.equals(data.status.position, selectedPreset?.status?.position);
          if (isOnPoint) {
            selectedPreset?.id && updatePreset({ presetId: selectedPreset.id, title: selectedPreset.title });
          } else {
            setAction('UpdateImage');
          }
        }
        setIsloading(false);
      }
    });
  };

  const onChange = useMemo<DropdownProps['onChange']>(
    () =>
      ({value}) => {
        setSelectedPreset(presetsRef.current.find((p) => p.id === value) ?? null);
      },
    []
  );

  const options = useMemo(() => {
    return presets.map((value) => ({key: value.id, value: value.id, label: value.title}));
  }, [presets]);

  const toPreset = useCallback(() => {
    if (selectedPreset) {
      absoluteMove(selectedPreset.status.position);
    }
  }, [selectedPreset, absoluteMove]);

  const onAction = useCallback((action: IAction) => {
    setAccessLoading(action);
    checkScope(`${action}CameraPreset`).then((r) => {
      setAccessLoading(null);
      if (r) {
        setAction(action);
      } else {
        setAccessDenied(action);
      }
    });
  }, []);

  return (
    <div className={'p-input-filled p-field'}>
      <label htmlFor={elId} css={$label}>
        {locale.header}
        {(loading || currentStatusLoading) && <span className={'mdi mdi-loading mdi-spin mdi-20px'} css={$loading} />}
      </label>
      <Dropdown
        id={elId}
        disabled={loading || currentStatusLoading}
        name={'preset'}
        placeholder={locale.presetPlaceholder}
        value={selectedPreset?.id ?? null}
        onChange={onChange}
        options={options}
        scrollHeight={'var(180rem / var(--bfs))'}
      />
      <div css={$actions} className={'p-mt-1'}>
        <Button
          type={'button'}
          className={'p-button-outlined p-button-secondary'}
          icon={accessLoading === 'Create' ? 'mdi mdi-loading mdi-20px mdi-spin' : 'mdi mdi-plus mdi-20px'}
          disabled={!!accessLoading}
          title={locale.create}
          onClick={() => onAction('Create')}
        />
        <WithPreview presetId={selectedPreset?.id}>
          <Button
            onClick={toPreset}
            type={'button'}
            disabled={!selectedPreset || loading}
            title={locale.goToPreset}
            className={'p-button-outlined p-button-secondary'}
            icon={'mdi mdi-image-filter-tilt-shift mdi-20px'}
          />
        </WithPreview>
        <Button
          onClick={() => onAction('Update')}
          type={'button'}
          title={locale.rename}
          disabled={!!accessLoading || !selectedPreset || loading}
          className={'p-button-outlined p-button-secondary'}
          icon={accessLoading === 'Update' ? 'mdi mdi-loading mdi-20px mdi-spin' : 'mdi mdi-rename-box mdi-20px'}
        />
        <Button
          onClick={updateImageOrDialog}
          type={'button'}
          disabled={!selectedPreset || loading}
          className={'p-button-outlined p-button-secondary'}
          title={locale.refreshPresetImage}
          icon={`mdi mdi-20px ${!loadingPresetImage ? 'mdi-camera' : 'mdi-spin mdi-loading'}`}
        />
        <Button
          onClick={() => onAction('Delete')}
          type={'button'}
          disabled={!!accessLoading || !selectedPreset || loading}
          title={locale.delete}
          className={'p-button-outlined p-button-secondary'}
          icon={accessLoading === 'Delete' ? 'mdi mdi-loading mdi-20px mdi-spin' : 'mdi mdi-delete mdi-20px'}
        />
      </div>
      {action === 'Create' && <CUDialog onHide={() => setAction(null)} />}
      {action === 'Update' && selectedPreset && <CUDialog preset={selectedPreset} onHide={() => setAction(null)} />}
      {action === 'Delete' && selectedPreset && (
        <DeleteDialog presetId={selectedPreset.id} onHide={() => setAction(null)} />
      )}
      {action === 'UpdateImage' && selectedPreset && (
        <UpdateImage preset={selectedPreset} onHide={() => setAction(null)} />
      )}
      {accessDenied && <AccessDeniedDialog action={accessDenied} onHide={() => setAccessDenied(null)} />}
    </div>
  );
};

const $loading = css`
  & {
    position: absolute;
    right: calc(-30rem / var(--bfs));
  }
`;

const $label = css`
  .p-field > & {
    position: relative;
    font-size: calc(16rem / var(--bfs));
    color: var(--text-color-secondary);
    font-weight: 500;
    margin-bottom: calc(15rem / var(--bfs));
  }
`;

const $actions = css`
  display: flex;

  > * {
    margin-right: var(--spacer-xs);
  }
  > *:last-child {
    margin-right: 0;
  }
`;

interface Eq<A> {
  readonly equals: (a: A, b: A) => boolean;
}

const getEqNumber = (threshold: number): Eq<number> => {
  return {
    equals: (a, b) => Math.abs(a - b) < threshold
  };
};

const getEqPosition = (threshold: IPtzValues): Eq<IPtzValues> => {
  const eqEntries = Object.entries(threshold).map(
    ([key, value]) => [key, getEqNumber(value)] as [keyof IPtzValues, Eq<number>]
  );
  return {
    equals: (a, b) => {
      if (a === b) {
        return true;
      }
      return eqEntries.every(([key, eq]) => eq.equals(a[key], b[key]));
    }
  };
};

const CUDialog: FC<{preset?: ICameraPreset; onHide: () => void}> = ({preset, onHide: _onHide}) => {
  const [dialogVisible, onHide] = useDialogVisible(_onHide);
  const locale = useLocale().ptzControls.presets;
  const {createPreset, setPreset} = usePresetsCommands();
  const [title, setTitle] = useState(preset?.title ?? '');
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(false);
  const [titleId] = useState(genId);
  return (
    <Dialog
      visible={dialogVisible}
      onHide={onHide}
      closable={false}
      header={preset ? locale.refresh : locale.create}
      footer={
        <Fragment>
          <Button
            icon={submitting ? 'mdi mdi-20x mdi-loading mdi-spin' : undefined}
            disabled={submitting}
            label={preset ? locale.refreshAction : locale.createAction}
            onClick={() => {
              setSubmitting(true);
              (preset ? setPreset(preset.id, title) : createPreset(title))
                .then(onHide)
                .catch((err) => {
                  console.error(err);
                  setError(true);
                })
                .finally(() => {
                  setSubmitting(false);
                });
            }}
          />
          <Button
            disabled={submitting}
            onClick={onHide}
            className={'p-button-secondary p-button-outlined'}
            label={locale.cancel}
          />
        </Fragment>
      }>
      <div className={'p-px-3 p-input-filled'} css={$dialogMessage}>
        <div className={'p-field'}>
          <label htmlFor={titleId}>{locale.title}</label>
          <InputText
            id={titleId}
            type={'text'}
            value={title}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              const pattern = /^[A-zA-я0-9 .,-]*$/;
              if (pattern.test(e.target.value)) {
                setTitle(e.target.value);
              }
            }}
          />
        </div>
        {error && <div style={{color: 'var(--error-color)'}}>{locale.unexpectedError}</div>}
      </div>
    </Dialog>
  );
};

const UpdateImage: FC<{preset: ICameraPreset; onHide: () => void}> = ({preset, onHide: _onHide}) => {
  const [dialogVisible, onHide] = useDialogVisible(_onHide);
  const locale = useLocale().ptzControls.presets;
  return (
    <Dialog
      visible={dialogVisible}
      onHide={onHide}
      closable={false}
      header={preset ? locale.refresh : locale.create}
      footer={
        <Fragment>
          <Button label={locale.ok} onClick={() => onHide()} />
        </Fragment>
      }>
      <div className={'p-px-3 p-input-filled'} css={$dialogMessage}>
        {<div className={'p-mb-2'}>{locale.cantRefreshPresetImage}</div>}
      </div>
    </Dialog>
  );
};

const DeleteDialog: FC<{presetId: string; onHide: () => void}> = ({presetId, onHide: _onHide}) => {
  const [dialogVisible, onHide] = useDialogVisible(_onHide);
  const locale = useLocale().ptzControls.presets;
  const {removePreset} = usePresetsCommands();
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(false);
  const [checkDelete, setCheckDelete] = useState(true);
  const [relatedAnalyticsCount, setRelatedAnalyticsCount] = useState(0);
  useEffect(() => {
    setCheckDelete(true);
    countAnalyticsByPreset(presetId).then((n) => {
      setCheckDelete(false);
      setRelatedAnalyticsCount(n);
    });
  }, [presetId]);
  return (
    <Dialog
      visible={dialogVisible}
      onHide={onHide}
      closable={false}
      header={locale.delete}
      footer={
        checkDelete ? (
          <div />
        ) : relatedAnalyticsCount > 0 ? (
          <Button onClick={onHide} className={'p-button-secondary p-button-outlined'} label={locale.ok} />
        ) : (
          <Fragment>
            <Button
              icon={submitting ? 'mdi mdi-20x mdi-loading mdi-spin' : undefined}
              disabled={submitting}
              className={'p-button-danger'}
              label={locale.yes}
              onClick={() => {
                setSubmitting(true);
                removePreset(presetId)
                  .then(onHide)
                  .catch((err) => {
                    console.error(err);
                    setError(true);
                  })
                  .finally(() => {
                    setSubmitting(false);
                  });
              }}
            />
            <Button
              disabled={submitting}
              onClick={onHide}
              className={'p-button-secondary p-button-outlined'}
              label={locale.no}
            />
          </Fragment>
        )
      }>
      <div className={'p-px-3'} css={$dialogMessage}>
        <span>
          {checkDelete
            ? locale.loadingDeleteDetail
            : relatedAnalyticsCount > 0
            ? locale.cannotDeleteDetail.replace('{{}}', relatedAnalyticsCount.toString())
            : locale.deleteDetail}
        </span>
        {error && (
          <Fragment>
            {' '}
            <br />
            <br />
            <span style={{color: 'var(--error-color)'}}>{locale.unexpectedError}</span>
          </Fragment>
        )}
      </div>
    </Dialog>
  );
};

const AccessDeniedDialog: FC<{action: IAction; onHide: () => void}> = ({action, onHide: _onHide}) => {
  const locale = useLocale().ptzControls.presets;
  const [dialogVisible, onHide] = useDialogVisible(_onHide);
  return (
    <Dialog
      visible={dialogVisible}
      onHide={onHide}
      closable={false}
      footer={<Button onClick={onHide} className={'p-button-secondary p-button-outlined'} label={locale.ok} />}
      header={locale.accessDenied}>
      <div className={'p-px-3'} css={$dialogMessage}>
        <span>{locale.accessDeniedDetail}</span>
      </div>
    </Dialog>
  );
};

const $dialogMessage = css`
  width: calc(480rem / var(--bfs));
  line-height: 1.5;
`;

export const WithPreview: FC<{presetId?: string}> = ({presetId, children}) => {
  const [src, setSrc] = useState('');
  const [loading] = usePresets();
  useEffect(() => {
    if (!src) return undefined;
    return () => {
      window.URL.revokeObjectURL(src);
    };
  }, [src]);

  useLayoutEffect(() => {
    if (!presetId) {
      setSrc('');
      return undefined;
    }
    const controller = new AbortController();
    const signal = controller.signal;
    setSrc('');
    fetchPresetPreview(presetId, signal)
      .catch((e) => {
        console.error(e);
        return null;
      })
      .then((blob) => {
        if (!signal.aborted && blob) {
          setSrc(window.URL.createObjectURL(blob));
        }
      });

    return () => {
      controller.abort();
    };
  }, [presetId, loading]);

  const nodeRef = useRef<HTMLDivElement | null>(null);

  const [visible, setVisible] = useState(false);

  useLayoutEffect(() => {
    const node = nodeRef.current;
    if (!node) return undefined;
    const onEnter = () => {
      setVisible(true);
    };
    const onLeave = () => {
      setVisible(false);
    };
    node.addEventListener('mouseover', onEnter);
    node.addEventListener('mouseleave', onLeave);
    return () => {
      node.removeEventListener('mouseover', onEnter);
      node.removeEventListener('mouseleave', onLeave);
    };
  }, []);

  return (
    <div ref={nodeRef} css={$withPreview}>
      {children}
      <video src={src} data-visible={visible && !!src} />
    </div>
  );
};

const $withPreview = css`
  position: relative;

  > video {
    pointer-events: none;
    border: calc(1rem / var(--bfs)) solid var(--player-video-bg);
    border-radius: var(--border-radius);
    background: var(--player-video-bg);
    position: absolute;
    bottom: calc(100% + 10rem / var(--bfs));
    left: calc(-54rem / var(--bfs));
    width: calc(210rem / var(--bfs));
  }
  > video[data-visible] {
    transition: opacity var(--transition-duration) ease;
  }
  > video[data-visible='false'] {
    opacity: 0;
  }
  > video[data-visible='true'] {
    opacity: 1;
  }
`;
