/** @jsx jsx */
import {css} from '@emotion/react';

export const previewContainer = css`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);

  pointer-events: none;
  height: 100%;
  width: 100%;

  .error-description {
    color: var(--text-color-active);
    text-align: center;
    position: absolute;
    bottom: calc(35rem / var(--bfs));
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const lowSpeedContainer = css`
  pointer-events: all;
  z-index: 2;
  background: rgba(var(--bg-raw), 0.4);
  container-type: inline-size;

  .buttons-wrapper {
    margin-bottom: calc(50rem / var(--bfs));
    .p-button.p-button-text.p-button-plain {
      color: var(--text-color);
    }
  }

  .error-description {
    width: 85%;
    font-size: calc(13rem / var(--bfs));
  }

  @container (max-width: 250px) {
    .error-description {
      width: 100%;
      font-size: calc(10rem / var(--bfs));
      bottom: calc(8rem / var(--bfs));
    }

    .buttons-wrapper {
      margin: 0;
      button span {
        &:first-of-type {
          margin: 0;
        }

        &:last-child {
          display: none;
        }
      }
    }
  }
`;
