/** @jsx jsx */
import {jsx, css} from '@emotion/react';
import {Button} from 'primereact/button';

const styles = css`
  position: absolute;
  left: calc(10rem / var(--bfs));
  top: calc(10rem / var(--bfs));
  z-index: 1;
  
  font-size: calc(18rem / var(--bfs));
`;

interface Props {
  onClick: () => void;
}

export const ArrowBack = (props: Props) => {
  return (
    <Button
      onClick={props.onClick}
      css={styles}
      icon="mdi mdi-arrow-left"
      className="p-button-rounded p-button-secondary p-button-outlined p-button p-component p-button-icon-only"
    />
  );
};
